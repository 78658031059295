<div *ngIf="isMobile">
  <div [ngClass]="isDarkTheme ? 'mobile-table-dark' : 'mobile-table-default'">
    <div class="row d-flex justify-content-between">
      <div class="col-12 text-primary mt-3" *ngIf="title">
        <span class="text-title">{{ title }}</span>
      </div>
      <div class="col-12 d-flex justify-content-end my-3" *ngIf="hideHeader">
        <input class="input" nbInput fullWidth placeholder="Pesquisa..." [(ngModel)]="search"
          (ngModelChange)="setFilter()" />
      </div>
    </div>

    <div class="row row-divider"></div>

    <div class="mobile-table-content' : 'mobile-table-content'" *ngFor="let row of sourceIterrator.data">
      <div class="row first-row"
        *ngIf="settings.actions.edit != false || settings.actions.delete != false || settings.actions.add != false">
        <div class="col-6 title-col-6" [ngClass]="getRowClass(row)">
          <div class="row">
            <div class="col-8">
              <span class="key-text">
                {{settings.actions.columnTitle}}
              </span>
            </div>
          </div>
        </div>

        <div class="col-6 d-flex justify-content-end gap-3 p-3 value-col-6" [ngClass]="getRowClass(row)">
          <div *ngIf="settings.actions.edit != false">
            <button size="small" class="ml-2" (click)="editEmissor(row)" nbButton
              [innerHtml]="settings.edit.editButtonContent" status="primary"></button>
          </div>
          <div *ngIf="settings.actions.delete != false">
            <button size="small" class="ml-2" (click)="deleteEmissor(row)" nbButton
              [innerHtml]="settings.delete.deleteButtonContent" status="danger"></button>
          </div>
          <div *ngIf="settings.actions.add != false">
            <button size="small" class="ml-2" (click)="addEmissor(row)" nbButton
              [innerHtml]="settings.add.addButtonContent" status="primary"></button>
          </div>
        </div>
      </div>

      <div class="row first-row" *ngFor="let col of getColumns(row)">
        <div class="col-6 title-col-6" [ngClass]="getRowClass(row)" (click)="setSort(col.key, col.sort)">
          <div class="row">
            <div class="col-8">
              <span class="key-text" [ngClass]="col.key == sortBy ? 'text-primary' : ''">
                {{ formatTitle(col.key) }}
              </span>
            </div>
            <div class="col-4 d-flex justify-content-end">
              <nb-icon [status]="col.sort == false ? 'basic' : col.key == sortBy ? 'primary' : ''"
                [icon]="col.key == sortBy ? orderBy == 'ASC' ? 'arrow-upward-outline' : 'arrow-downward-outline' : 'flip-outline'"></nb-icon>
            </div>
          </div>
        </div>

        <div class="col-6 d-flex justify-content-end value-col-6" [ngClass]="getRowClass(row)">
          <span class="value-text">
            {{ formatValue(col, row) }}
          </span>
        </div>
      </div>

      <div class="row row-divider"></div>
    </div>

    <ng2-smart-table [source]="dataSource" [settings]="emptySettings">
    </ng2-smart-table>
  </div>
</div>

<ng2-smart-table *ngIf="!isMobile" class="default-table" [source]="dataSource" [settings]="settings"
  (edit)="editEmissor($event.data)" (copyLink)="copyLinkEmissor($event.data)"  (delete)="deleteEmissor($event.data)" (add)="addEmissor($event.data)">
</ng2-smart-table>