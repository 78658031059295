import { ApplicationRef, Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import * as signalR from "@microsoft/signalr";
import { HubConnection } from "@microsoft/signalr";
import { NbRoleProvider } from "@nebular/security";
import { NbAuthService } from "@nebular/auth";
import { environment } from "../../../environments/environment";
import { ChatData } from "../../@core/interfaces/portal/chatData";
import { StorageService } from "../../@core/backend/portal/services/storage.service";
import { Router } from "@angular/router";
import { LayoutService } from "../../@core/utils";

@Injectable({ providedIn: "root" })
export class ChatService {
  isMobile: boolean = this.layoutService.isMobile();

  totalInQueue = 0;
  totalNewMessage = 0;
  newMessage = 0;

  connection!: HubConnection;
  sessionId!: string;

  bShowHideChatList = false;
  bShowHideChatListIcon = false;
  bShowHideChatProvIcon = false;
  bShowHideChatForm = false;
  connected = false;

  //Fila de espera - são as pessoas/fornecedor esperando.
  chatListItems = [];
  queueObject = { room: "", message: "", index: -1, end: false };
  bUseQueueSplash = false;

  channelObj!: any;
  userName!: string;
  messages = [];
  isTypingMessage = "";
  endByProvider = false;
  endByTimeout = false;
  messageSubject$ = new Subject();

  constructor(
    private appRef: ApplicationRef,
    private chatData: ChatData,
    private authService: NbAuthService,
    private aclService: NbRoleProvider,
    private storageService: StorageService,
    private layoutService: LayoutService,
    private router: Router
  ) {
    this.router.events.subscribe(_ => this.hideAll());
  }

  async connect() {
    this.resetVariables();
    
    const roles = await this.getUserInfo();
    const token = await this.authService.getToken().toPromise();

    this.setUserRole(roles);

    await this.startSignalR(token.getValue(), roles);
  }

  async disconnect() {
    this.resetVariables();

    await this.connection.stop();

    this.connection = undefined;
    this.sessionId = undefined;

    Object.defineProperty(WebSocket, "CLOSED", { value: 3 });
  }

  private async startSignalR(token: string, roles) {
    const baseUrl = this.storageService.url;

    const pos = baseUrl.lastIndexOf("api");
    const url = baseUrl.substring(0, pos) + "chathub" + baseUrl.substring(pos + 3);

    Object.defineProperty(WebSocket, "OPEN", { value: 1 });

    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(url, { accessTokenFactory: () => token, withCredentials: false })
      .withAutomaticReconnect()
      .build();

    this.connection.onclose(() => {
      this.changeStates(false, roles);
    });

    this.connection.onreconnecting(() => {
      this.changeStates(false, roles);
    });

    this.connection.onreconnected(() => {
      this.changeStates(true, roles);
    });

    await this.connection.start();

    this.changeStates(true, roles);

    this.sessionId = this.connection.connectionId;
    this.chatData.checkState(this.sessionId).subscribe();
  }

  private changeStates(connected: boolean, roles: any): void {
    this.connected = connected;

    if (!connected) {
      this.bShowHideChatList = false;
      this.bShowHideChatForm = false;
      this.bUseQueueSplash = false;
    }

    if (roles.isAdmin || roles.isBuyer) {
      this.connection.on("EndByProvider", (response: any) => {
        if (this.channelObj.channelId == response) {
          this.endByProvider = true;
        }
      });

      this.connection.on("EndByTimeout", (response: any) => {
        if (this.channelObj.channelId == response) {
          this.endByTimeout = true;
        }
      });

      this.connection.on("OnJoin", () => {
        this.endByProvider = false;
        this.endByTimeout = false;
      });

      this.connection.on("UpdateTotals", (response: any) => {
        if (response.channelId) {
          if (response.type == "new_message_from_provider") {
            const queue = this.chatListItems.find(
              (x) => x.channelId === response.channelId
            );

            if (queue) {
              if (!queue.newMessage) {
                queue.newMessage = 0;
              }

              if (this.bShowHideChatForm && this.channelObj.channelId == response.channelId) {
                queue.newMessage = 0;
              } else {
                queue.newMessage += response.total;
                if (!this.bShowHideChatList) {
                  this.totalNewMessage += 1;
                }
              }
            }
          }
        }

        if (response.type == "new_message") {
          this.totalNewMessage += response.total;
        }

        if (response.type == "new_in_queue" && !this.bShowHideChatList) {
          this.totalInQueue += response.total;
        }

        this.appRef.tick();
      });

      this.connection.on("UpdateQueueList", (response: any) => {
        const listCopy = this.chatListItems.map((a) => Object.assign({}, a));
        this.chatListItems = response;

        this.chatListItems.forEach((element) => {
          const item = listCopy.find((x) => x.channelId == element.channelId);

          if (item) {
            if (!item.newMessage) item.newMessage = 0;
            element.newMessage = item.newMessage;
          }
        });

        this.appRef.tick();
      });
    }

    if (roles.isProvider) {
      this.bUseQueueSplash = true;
      
      this.connection.on("UpdateQueue", (response: any) => {
        this.queueObject = response;

        if (this.queueObject.end) {
          this.bUseQueueSplash = true;
          this.messages = [];
          this.newMessage = 0;
        }

        this.appRef.tick();
      });

      this.connection.on("UpdateTotals", (response: any) => {
        if (response.channelId) {
          if (response.type == "new_message_from_buyer" && !this.bShowHideChatForm) {
            this.newMessage += response.total;
          }
        }

        this.appRef.tick();
      });
    }

    this.connection.on("Join", async (response: any) => {
      this.bUseQueueSplash = false;
      this.channelObj = response;

      const queue = this.chatListItems.find(
        (x) => x.channelId == response.channelId
      );

      if (queue) {
        queue.newMessage = 0;
      }

      const userInfo = await this.getUserInfo();

      this.userName = "- " + (userInfo.isProvider ? response.buyerName : response.providerName);
      this.messages = response.messages;

      this.appRef.tick();
      this.messageSubject$.next();
    });

    this.connection.on("SendMessage", (response: any) => {
      if (this.channelObj.channelId == response.channelId) {
        this.messages.push(response);

        this.appRef.tick();
        this.messageSubject$.next();
      }
    });

    this.connection.on("IsTyping", (response: any) => {
      if (this.channelObj.channelId == response.channelId) {
        this.isTypingMessage = response.message;
        this.appRef.tick();
      }
    });

    this.connection.on("UpdateSessionId", (response: any) =>
      (this.sessionId = response)
    );
  }

  private setUserRole(roles: any): void {
    this.bShowHideChatListIcon = roles.isAdmin || roles.isBuyer;
    this.bShowHideChatProvIcon = roles.isProvider;
  }

  private resetVariables(): void {
    this.totalInQueue = 0;
    this.totalNewMessage = 0;

    this.bShowHideChatList = false;
    this.bShowHideChatListIcon = false;
    this.bShowHideChatProvIcon = false;
    this.bUseQueueSplash = false;
    this.connected = false;

    this.queueObject = { room: "", message: "", index: -1, end: false };
    this.messages = [];
  }

  sendMessage(message: string) {
    if (message) {
      const obj = {
        key: this.sessionId,
        message: message,
      };
      this.chatData.sendMessage(this.channelObj.channelId, obj).subscribe();
    }

    this.appRef.tick();
  }

  join(channelId: string): void {
    this.chatData.join(channelId, this.sessionId).subscribe((response: any) => {
      this.bShowHideChatForm = true;

      this.appRef.tick();
      this.messageSubject$.next();
    });
  }

  addQueue(): void {
    this.chatData.addQueue(this.sessionId).subscribe((response: any) => {
      this.queueObject = response;

      this.appRef.tick();
    });
  }

  cancelQueue(): void {
    this.chatData.cancelQueue(this.sessionId).subscribe((response: any) => {
      this.queueObject = response;

      this.appRef.tick();
    });
  }

  hideAll(): void {
    if (this.isMobile) {
      this.bShowHideChatList = false;
      this.bShowHideChatForm = false;
    }
  }

  showHideChatList() {
    this.bShowHideChatList = !this.bShowHideChatList;
    
    if (this.bShowHideChatList) {
      this.totalInQueue = 0;
      this.totalNewMessage = 0;
    }

    this.appRef.tick();
  }

  showHideChatForm() {
    this.newMessage = 0;
    this.bShowHideChatForm = !this.bShowHideChatForm;

    this.appRef.tick();
  }

  async getUserInfo(): Promise<any> {
    const roles = await this.aclService.getRole().toPromise();

    const info = {
      isAdmin: roles.indexOf("admin") >= 0,
      isBuyer: roles.indexOf("buyer") >= 0,
      isProvider: roles.indexOf("provider") >= 0,
    };

    return info;
  }

  getMessageSubject(): Observable<any> {
    return this.messageSubject$.asObservable();
  }

  endConversation(channelId: string, endByUser = false) {
    this.chatData.endConversation(channelId).subscribe(() => {
      if (this.channelObj.channelId == channelId) {
        this.userName = "";
        this.channelObj = {};
        this.messages = [];
        this.bShowHideChatForm = endByUser;
      }

      this.appRef.tick();
    });
  }

  doTyping(state: boolean): Observable<any> {
    return this.chatData.sendIsTyping(
      this.channelObj.channelId,
      this.sessionId,
      state
    );
  }
}
